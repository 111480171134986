import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Privacy = () => {
  return (
    <Layout>
      <Seo title="Privacy" />
      Updated 3 January 2021

<h2>Cook With Heather Privacy Policy</h2>
<p>
    This Privacy Policy discloses how we gather, use and protect your personal data.
    </p>
<p>
    This Privacy Policy applies to personal data collected by Cook With Heather through its 
website cookwithheather.com or, as described below, by other means during the course of 
its business.
</p>

<ol>
<li key="controller">Controller</li>
<p>
    Responsible data controller in the meaning of Art. 4 No. 7 GDPR is:
</p>
<p>
Cook With Heather, Raleigh, North Carolina 27610, USA; E-Mail: info@cookwithheather.com; Website: cookwithheather.com
</p>
<p>
You can contact the data protection officer via email at: privacy@cookwithheather.com
</p>

<li key="general_info">General Information</li>
<p>
During the course of carrying out our business and performing our services, Cook With Heather 
collects personal data to conduct our business, provide and market our services, and meet our 
legal obligations. Likewise, we may also collect personal data for other purposes, which we 
would describe in more detail to you at the point we collect the personal data.
</p>
<p>
You may refuse to provide us with some or all of your personal data; however, this may limit the 
ways in which we can interact with you, including providing you with our services.
</p>

<li key="logfiles">Logfiles</li>
<p>
    We collect personal data through our internet access logs. When you access our website, your 
internet address is automatically collected and is placed in our internet access logs (i.e., 
log files on server). We also record the URLs of the websites and pages you visit, the times 
and dates of such visits, information about the computer hardware and software you use.
</p>
<p>
This information may include Internet protocol (IP) addresses, browser type and version, 
internet service provider (ISP), referring/exit pages, operating system, date/time stamp, 
and/or clickstream data, number of visits, websites from which you accessed our site (Referrer), 
and websites that are accessed by your system via our site.
</p>
<p>
The processing of this data is necessary for the provision and the security of our website. 
The legal basis for this processing is Art. 6(1)(f) GDPR. We do not link these data to certain 
individuals. After the processing for the mentioned purposes, the data will be deleted.
</p>

<li key="contact">Contact</li>
<p>
On our website we use several contact forms which can be used to get in contact with us 
electronically. To handle your request it is necessary to provide us with a valid email address, 
and, in addition, we store your IP address and the time of your request to prevent any misuse of 
our contact forms. Alternatively, you may contact us via the e-mail addresses cited above 
(Section 1). If you use one of these channels, the personal data transmitted will be stored 
automatically by Cook With Heather as data controller to handle our contact with you. The legal 
basis for this processing is Art. 6(1)(f) GDPR.
</p>
<p>
In some cases we may ask you for further information, such as name, occupation, address or 
telephone number. Regarding the processing of this personal data you will be asked to grant 
your explicit consent. The legal basis for this processing is Art. 6(1)(a) GDPR.
</p>

<li key="registration">Registration on our website</li>
<p>
If you make use of our website to register for certain services or events, you will be asked 
to provide personal data. The information inserted in the respective registration form will be 
transmitted to Cook With Heather as data controller. The legal basis for the processing of 
data - which is necessary to provide you with the respective service - is Art. 6(1)(b) GDPR. 
Regarding the processing of further personal data you will be asked to grant 
your explicit consent, and the legal basis for that processing is Art. 6(1)(a) GDPR.
</p>

<li key="unsolicited">Unsolicited special categories of personal data</li>
<p>
Cook With Heather does not generally seek to collect special categories of personal data 
through this site.
</p>
<p>
"Special categories of personal data" includes the various categories of personal information 
and data identified by privacy laws of United States, European and other jurisdictions as 
requiring special treatment, including in some circumstances the need to obtain explicit 
consent. These categories may include personal data revealing racial or ethnic origin, 
political opinions, religious or philosophical beliefs, or trade union membership, genetic 
data, biometric data, data concerning health or data concerning sexual orientation (Art. 9 GDPR).
</p>
<p>
Therefore, we advise you not to provide any unsolicited sensitive personal data. 
Cook With Heather applies HTTPS or other appropriate technical and organizational measures 
to secure the personal data transmitted over its website.
</p>

<li key="newsletter">Newsletter</li>
<p>
We may provide the opportunity to sign-up for a periodic email newsletter by entering in your 
email address and confirming it by clicking on a confirmation link sent to you by us by e-mail 
(double opt-in). The legal basis for this processing is Art. 6(1)(a) GDPR.
</p>
<p>
In some cases we may ask you for further information, such as name, occupation, address or 
telephone number. Regarding the processing of this personal data you will be asked to grant 
your explicit consent. The legal basis for this processing is Art. 6(1)(a) GDPR.
</p>
<p>
If you subscribe for our periodic email newsletter your IP address as well as data and time 
of subscription will be collected and stored at Cook With Heather as data controller. This allows 
us to prevent misuse of our services or of the e-mail address of the subscriber. The legal 
basis for this processing is Art. 6(1)(f) GDPR. This data is only used for the distribution 
of the newsletter. The subscription may be terminated by you at any time by following the 
unsubscribe instructions included in each newsletter. We will delete the relevant personal 
data promptly upon your unsubscribe instruction.
</p>

<li key="blogs">Blogs</li>
<p>
Our website offers publicly accessible blogs. You should be aware that any information you 
provide in these areas may be read, collected and used by others who read them.
</p>

<li key="cookies">Cookies (General Information)</li>
<p>
Cook With Heather's websites make use of cookies to save and retrieve information about 
your visit to our sites. Cookies are small files of software which save and retrieve information 
about your visit to a website or application. They reside in your internet browser to help 
remember your preferences and previous activity. You can find more information about cookies 
at <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a> and 
<a href="http://www.youronlinechoices.eu">www.youronlinechoices.eu</a>.
</p>
<p>
Cookies currently used on our site identify you as an anonymized number. We and our partners 
(e.g., marketing partners, affiliates, or analytics providers) use cookies and log files to 
analyze trends, administer the website, track users movements around the website, and gather 
demographic information about our user base. We may receive anonymized reports based on the 
use of these technologies by our partners on an aggregated basis. We also use this data to 
provide the site’s content, ensure the functionality of our information technology systems, 
and to optimize our website. The legal basis for this processing is Art. 6(1)(f) GDPR. The 
data of log files will be stored separately from your other personal data.
</p>
<p>
Users can control and refuse the use of cookies at the individual browser level. Cookies 
installed can be deleted. If you have visited our website in the past and would like to make 
changes to your cookie preferences, please first clear the cookies from your browser and then 
update your cookie preferences. If you reject cookies, you may still use our website, but your 
ability to use some features or areas of our website may be limited. If you are accessing the 
website from the EU region you can change your cookie settings from here.
</p>
<p>
When you visit our website, the following categories of cookies will be set in your browser:
</p>

<ol>
<li key="necessary">Strictly necessary cookies</li>
<p>
These cookies are essential in order to enable you to move around a site and use its features. 
Without these cookies services you have asked for cannot be provided.
</p>
<p>
Registered Visitor Cookie - A unique identifier given to each registered user, used to 
recognize you anonymously through your visit and when you return to the site.
</p>

<li key="performance">Performance cookies</li>
<p>
These cookies collect information so that we can analyse how our visitors use our site. These 
cookies do not collect information that identifies you. All information these cookies collect 
is anonymous and is only used to improve how our site works.
</p>

<li key="functional">Functional cookies</li>
<p>
These cookies allow websites and applications to remember choices you make (e.g., such as your 
user name or the region you are in) and provide enhanced, more personal features.
</p>
<p>
We may use information collected from functional cookies to identify user behaviour and to 
serve content based on the user profile. These cookies cannot track your browsing activity on 
other websites. They do not gather any information about you that could be used for advertising 
or to record where you’ve been on the Internet outside our site.
</p>

<li key="targeting">Targeting cookies</li>
<p>
In order to keep our website services relevant, easy to use and up-to-date, and understand 
interest in our services we use web analytics services to help us understand how people use 
the site.
</p>
<p>
Cookies allow web analytics services to recognise your browser, device, or IP address and, 
for example, identify whether you have visited our website before, what you have previously 
viewed or clicked on, and how you found us. The information is only used for statistical 
purposes, and it helps us to analyse patterns of user activity, user interests, and to develop 
a better user experience.
</p>
</ol>

<li key="third_party">Third Party Applications</li>
<p>
We will respect and protect your privacy as set out in this Privacy Policy. We will share 
your personal data with third parties only in the ways that are described in this Privacy 
Policy - and do not and will not sell your personal data.
</p>
<p>
Cook With Heather shares your personal data with third parties, in particular 
non-Cook With Heather parties, only with your express consent or under another lawful 
basis for processing under the applicable law.
</p>
<p>
Categories of non-Cook With Heather parties with which we share your personal data include 
vendors such as host and cloud service providers, marketing and mailing agencies, and 
sub-contractors involved in the fulfilment of our contractual obligations towards our clients. 
The legal basis for this processing is Art. 6(1)(f) GDPR.
</p>
<ol>
{/* <li>AddThis</li>
Our website employs the bookmark service AddThis. AddThis is a service of Clearspring 
Technologies Inc., 8000 Westpark Drive, Suite 625, McLean, VA 2210, USA, http://www.addthis.com/. 
Each time our website receives an access request equipped with an AddThis component, the 
component prompts your browser to download an image of this component from AddThis. Through 
this process, AddThis is informed exactly which page of our website is being accessed. In 
addition, AddThis also records your IP address, browser type, browser language, the website 
previously accessed as well as the date and time you visited the site and uses this data to 
compile an anonymized user profile. This data allows AddThis and its partner companies to 
direct targeted personalized ads at website users based on their particular interests. The 
display of advertising material occurs based on a browser cookie put in place by AddThis, 
which analyzes user interaction with the website. You can permanently prevent AddThis from 
placing a cookie on your browser by downloading and installing the opt-out cookie available 
at the following link: http://www.addthis.com/privacy/opt-out.

If you install the opt-out cookie you may not be able to make full use of all the functions 
of our website. You can find the privacy policy for AddThis 
at http://www.addthis.com/privacy/privacy-policy. Finally and currently, AddThis includes the 
following widgets on our website for the purpose of sharing the page on the corresponding 
social media sites:

Facebook

Google +

Twitter

LinkedIn

Pocket

WeChat */}

{/* <li>Facebook conversion pixels</li>
We use the “Custom Audience pixel” of Facebook Inc., 1601 S. California Ave, Palo Alto, CA 
94304, USA (“Facebook”) on our website. With its help, we can keep track of what users do after 
they see or click on a Facebook advertisement. This enables us to monitor the effectiveness of 
Facebook ads for purposes of statistics and market research. Data collected in this way is 
anonymous to us, which means we cannot see the personal data of individual users. However, 
this data is saved and processed by Facebook. Facebook can connect this data with your Facebook 
account and use it for its own advertising purposes, in accordance with Facebook’s Data Policy 
which can be found at https://www.facebook.com/about/privacy/. You can allow Facebook and its 
partners to place ads on and outside of Facebook. A cookie can also be saved on your device 
for these purposes.

Please click here if you would like to withdraw your consent https://www.facebook.com/settings/?tab=ads#_=_
 */}

<li key="google_analytics">Google Analytics</li>
<p>
Our website uses Google Analytics, a web analysis service of Google, Inc., 1600 Amphitheatre 
Parkway, Mountain View, CA 94043 USA, www.google.com (“Google Analytics” or “Google”). Google 
Analytics employs cookies that are stored to your computer in order to facilitate an analysis 
of your use of the site. The information generated by these cookies, such as time, place and 
frequency of your visits to our site, including your IP address, is transmitted to Google’s 
location in the US and stored there.
</p>
<p>
In using Google Analytics our website employs the extension “anonymizeIp”. In doing so, Google 
abbreviates and thereby anonymizes your IP address before transferring it from EU/EEA member 
states. Google uses this information to analyze your use of our site, to compile reports for 
us on internet activity and to provide other services relating to our website.
</p>
<p>
Google may also transfer this information to third parties where required to do so by law or 
where such third parties process this data on Google’s behalf. Google states that it will in 
never associate your IP address with other data held by Google. You can prevent cookies from 
being installed by adjusting the settings on your browser software accordingly as noted 
elsewhere in this Privacy Policy. You should be aware, however, that by doing so you may not be 
able to make full use of all the functions of our website.
</p>
<p>
Google Analytics also offers a deactivation add-on for most current browsers that provides you 
with more control over what data Google can collect on websites you access. The add-on tells the 
JavaScript (ga.js) used by Google Analytics not to transmit any information about website visits 
to Google Analytics. However, the browser deactivation add-on offered by Google Analytics does 
not prevent information from being transmitted to us or to other web analysis services we may 
engage.
</p>
<p>
Google Analytics also uses electronic images known as web beacons (sometimes called single 
pixel gifs) and are used along with cookies to compile aggregated statistics to analyze how 
our site is used.
</p>
<p>
You can find additional information on how to install the browser add-on referenced above at 
the following link: https://tools.google.com/dlpage/gaoptout?hl=en.
</p>
<p>
For the cases in which personal data is transferred to the US, Google has self-certified 
pursuant to the EU-US Privacy Shield (https://www.privacyshield.gov/EU-US-Framework).
</p>

<li key="google_tag_manager">Google Tag Manager (GTM)</li>
<p>
Google Tag Manager (GTM”) is a tag management system to manage JavaScript and HTML tags used 
for tracking and analytics on websites. Tags are small code elements that, among other things, 
are used to measure traffic and visitor behaviour: to understand the effect of online 
advertising and social channels; to set up remarketing and orientation towards target groups; 
and to test and optimize websites. GTM makes it easier for us to integrate and manage our tags. 
We use GTM on our website to include the following tracking tools:
</p>

<ul>
<li key="google_analytics">Google Analytics</li>

{/* ● Facebook Conversion Pixels

● Twitter tailored audience */}
</ul>

<p>
If you have performed deactivation, GTM takes this deactivation into account. For more 
information about GTM’s privacy practices can be found at 
https://policies.google.com/privacy?hl=en and terms of use at 
https://www.google.com/analytics/tag-manager/use-policy/.
</p>

{/* <li>Twitter tailored audience</li>
Tailored audiences is the tool of Twitter Inc., 1355 Market Street, Suite 900, San Francisco, 
CA 94103, USA, www.twitter.com (“Twitter”), used to target existing users and customers to 
create remarketing campaigns. Targeting activity can include directly reaching out to users 
or visitors to the Cook With Heather website and campaign pages and/or retargeting previous 
customer lists. Twitter sets a minimum size limit for a tailored audience to 500 users. If the 
tailored audience does not match 500 Twitter users, it will display as "audience too small" and 
will not be available for targeting. Details about Twitter’s policies for conversion tracking 
and tailored audiences can be found at https://business.twitter.com/en/help/ads-policies/other-policy-requirements/policies-for-conversion-tracking-and-tailored-audiences.html. */}

</ol>

<li key="social_media_links">Social Media Links</li>
<p>
You can find Cook With Heather on the following social media:
</p>
<ul>
<li key="twitter">Twitter</li>
<li key="facebook">Facebook</li>
<li key="youtube">Youtube</li>
<li key="instagram">Instagram</li>
</ul>
<p>
These providers are controllers for the relevant data processing.
</p>
<li key="deletion">Deletion and Term of Storage</li>
<p>
Cook With Heather processes and stores your personal data only for the time necessary to 
achieve the stated purpose (unless otherwise stated in this Privacy Policy) and as permitted 
under the applicable laws or regulations.
</p>
<p>
As soon as the purpose for data storage is achieved, or the appropriate term of storage provided 
for by any applicable laws or regulations expires, your personal data will be deleted consistent 
with this and any other applicable Cook With Heather policies (e.g., document retention and/or 
records management policy) unless your personal data are required for the formation of or 
performance under a contract.
</p>
<li key="rights">Your rights as data subject</li>
<p>
You have the following rights:
</p>
<ul>
    <li key="access">Right of access (Art. 15 GDPR)</li>
<p>
In principle, you have the right to receive information on the points mentioned in Art. 15 
GDPR. You also have the right to request a copy of your personal data in accordance with Art. 
15 (3) GDPR.
</p>
<li key="rectification">Right to rectification or erasure (Art. 16 and 17 GDPR)</li>
<p>
You have the right to have incorrect personal data corrected. In addition, you have the right 
to demand that your personal data is deleted if further processing is no longer necessary, if 
processing is unlawful or if you have withdrawn your consent.
</p>
<li key="restriction">Right to restriction of processing (Art. 18 GDPR)</li>
<p>
If the conditions in Art. 18 GDPR are met, you have the right to have the processing of your 
personal data restricted, i.e. to prevent further processing for the time being.
</p>
<li key="object">Right to object (Art. 21 GDPR)</li>
<p>
If the processing of your personal data is based on Art. 6 (1)e or f GDPR, you have the right 
to object to the processing if the further requirements of Art. 21 GDPR are met.
</p>
<li key="portability">Right to data portability (Art. 20 GDPR)</li>
<p>
Within the limits of Art. 20 GDPR, you have the right to receive your personal data in a 
machine-readable format in order to forward it or have it forwarded to another controller.
</p>
<p>
You can require this by sending us an email at privacy@cookwithheather.com.
</p>
<p>
Without prejudice to any other administrative or judicial remedy, you have the right to lodge 
a complaint with a supervisory authority, in particular in the location of your habitual 
residence, place of work or place of the alleged infringement if you consider that the 
processing of personal data relating to you infringes the applicable data protection laws and 
regulations.
</p>
</ul>
<li key="changes_to_policy">Changes to the Policy</li>
<p>
This Privacy Policy may be updated from time to time and will note the date of any updates.  
In the event of any material and significant changes we will endeavor to provide a more 
prominent notice of the changes to this Privacy Policy.
</p>
</ol>
    </Layout>
  )
}

export default Privacy;
